.Flex .RightContainer, .Flex .CheckMailPageRightContainer {
  flex: 3;
  padding-top: 50px;
}

.pinkInput div.input,
.pinkInput div.input input::placeholder {
  border-color:#98379a;
  color:#98379a;
}

.Flex {
  display: flex;
  margin: 0;
  padding: 0;
  /* height: 95%; */
}

.CheckMailPageRightContent {
  margin-left: 5%;
  margin-right: 5%;
  padding: 60px 10px;
  /* max-width: 400px; */
}

.Flex .RightContent {
  margin-left: 5%;
  margin-right: 5%;
  padding: 60px 10px;
  max-width: 360px;
  text-align: left;
}


.Flex .RightContent .ui.button,
.Flex .RightContent .btn-lg {
  margin-top: 10px;
  width: 100px;
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 17px;
  padding-left: 30px;
  padding-right: 30px;
  width: 120px;
}

button.PinkButton {
  background-color:#98379a;
  border-color:#98379a;
}

.Flex .RightContent p.description,
.CheckMailPageRightContainer p.description {
  font-size: 17px;
  text-align: left;
}

.HomeCarousel {
  padding-top: 50px;
}

.HomeCarousel .BorderContainer {
  border-width:1.2px;
  border-radius:13px;
  border-style:solid;
  padding: 40px;
  border-color:#98379a;
  text-align: left;
  font-size: 16px;
}

.VerticalImage {
  flex: 5;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: 30p;
  /* justify-content: center; */
}

.VerticalImage img {
  height: 95%;
  max-width: 100%;
}