img.logo-navbar {
    max-height: 60px;
    vertical-align: middle;
}

.navbar-nav .nav-item a  {
    color: #151515 !important;
}

a.nav-link {
    color: #151515 !important;
}

.navbar {
    margin-bottom: 0px;
}