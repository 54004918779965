body > #root > div {
  /* height: 100vh; */
}
#root {
  height: 100%;
  min-height: 100vh;
}
body #root {
  height: 100%;
}
@media all and (min-height: 980px) {
  .Flex {
    height: calc(100% - 133px);
  }
}
.App {
  text-align: center;
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ui.green.button, .ui.green.buttons .button {
  background-color: #0E8C0B;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Center modal view */
.modal {
  height: auto;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
}
/* LOGIN */
.Login {
  margin: 0;
  padding: 0;
  height: 100%;
}
@media all and (min-width: 480px) {
  .LoginForm {
    padding: 60px 10px;
  }

  .LoginForm .ui.form {
    margin: 0 auto;
    width: 320px;
  }
  .LoginForm Button {
    margin-top: 10px;
    width: 100%;
  }
}
@media (max-width: 620px) {
  #SelectPath {
    display: flex;
    flex-direction: column;
  }
  .Hero {
    display: none !important;
  }
  .Login {
    display: block !important;
  }
  .LoginForm {
    margin: 0 auto;
    width: 100%;
    padding: 40px 10px;
  }
}
/* TODO - handle this when two divs enabled */
.Login {
  display: flex;
}
.LoginForm {
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Hero {
  flex: 1;
  background-color: #4947B9;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: 30p;
  /* justify-content: center; */
}
.Hero.purple {
  background-color: #98379A;
}
#Login_hero_content {
  margin: 40px;
  color: #fff;
  font-size: 20px;
}
#Login_hero_content h1 {
  font-size: 100px;
}
#Login_hero_content a {
  text-decoration: underline;
  color: #fff;
}
.Login Button {
  margin-top: 10px;
}
/* HOME */
#main_container {
  max-width: 1200px;
  margin: 0 auto;
}
#SelectPath {
  display: flex;
}
.path_options {
  flex: 1;
  margin: 10px;
  padding: 10px;
}
.path_options Button {
  margin-bottom: 10px;
}
#Path1 {
  background-color: #61dafb;
}
#Path2 {
  background-color: #61dafb;
}
#Path3 {
  background-color: #61dafb;
}
#Path4 {
  background-color: #61dafb;
}
#SelectPath.hidden { display:none; }
.hidden { display:none; }

/* HOME - path selected */
#path_content {
  margin: 20px;
}
#path_content .container {
  margin: 20px 0;
}
#dropdown {
  background-color: #5C4D5D;
  color: #fff;
}
.dropdown_selection div {
  /* margin: 10px !important; */
}
.ui.inline.dropdown .dropdown.icon {
  /* margin: inherit; */
  margin: -.78571429em;
}
.content.active {
  background-color: #fafafa;
}
.content.active pre {
  padding: 15px;
  color: #0C690B;
}
.dataset1_dropdown {
  background-color: #4947B9 !important;
  color: #fff !important;
}
.dataset2_dropdown {
  background-color: #98379A !important;
  color: #fff !important;
}
.margin-top-50 {
  margin-top: -50px !important;
}
/* Carousel */
.carousel-indicators button {
  height: 5px !important;
}
.carousel-indicators button:first-child {
  background-color: #4947B9 !important;
}
.carousel-indicators button:nth-child(2) {
  background-color: #98379A !important;
}
.carousel-indicators button:nth-child(3) {
  background-color: #0E8C0B !important;
}
.carousel-indicators button {
  opacity: 0.2 !important;
  transition: opacity 0.6s ease !important;
}
.carousel-indicators button.active {
  opacity: 1 !important;
}
/* Course suggestion alignment fix */
.course-suggestions-grid {
  margin: 0 0 0 -3px !important;
}
/* Not logged in main page */
.VerticalImage {
  background-image: url('/public/VerticalSideImage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
@media (max-width: 890px) {
  .VerticalImage {
    display: none !important;
  }
}
/* Home carousel */
.HomeCarousel .carousel-indicators button {
  width: 10px;
  height: 10px !important;
  border-radius: 5px;
  box-sizing: border-box;
  background: #4947B9 !important;
  padding: 0;
  border: 0 !important;
}